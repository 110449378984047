<template>
  <div class="receorderdetail-container position-relative">
    <div class="auto-table-flex">
      <div class="text-center sale-font mb-25">
        订单号：{{ merchantList.order_no }}
      </div>
      <div>
        <el-form
          label-width="120px"
          :model="merchantList"
          ref="addForm"
          :inline="true"
          :hide-required-asterisk="true"
        >
          <div>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="客户姓名" prop="title">
                  <div v-if="merchantList.member">
                    {{ merchantList.member.fullname }}
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="客户电话" prop="storeType">
                  <div v-if="merchantList.member">
                    {{ merchantList.member.mobile }}
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="城市仓" prop="warehouseId">
                  <div
                    v-if="
                      merchantList.store_delivery &&
                      merchantList.store_delivery.logistics
                    "
                  >
                    {{ merchantList.store_delivery.logistics.name }}
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="自提点信息" prop="carLicense">
                  <div v-if="merchantList.store_delivery">
                    {{ merchantList.store_delivery.name }}
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="mb-30">
              <el-table
                :data="goodsData"
                v-loading="loading"
                :border="true"
                :show-summary="true"
                ref="goodsTable"
                :summary-method="goodsCount"
                :header-cell-style="{
                  color: '#333333',
                  background: '#EFF6FF',
                  'text-align': 'center',
                }"
              >
                <el-table-column
                  type="index"
                  width="80"
                  align="center"
                  label="序号"
                >
                </el-table-column>
                <el-table-column prop="goodsName" align="center" label="主图">
                  <template slot-scope="scope" v-if="scope.row.product">
                    <el-image
                      :src="scope.row.product.kv"
                      :preview-src-list="[scope.row.product.kv]"
                      class="kv pointer"
                      alt=""
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="title"
                  align="left"
                  width="250"
                  label="商品信息"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.row.product">
                      <div class="mb-15" style="font-weight: bold">
                        <span v-if="scope.row.product.title">{{
                          scope.row.product.title
                        }}</span
                        ><span v-if="scope.row.product.spec"
                          >#{{ scope.row.product.spec }}</span
                        ><span v-if="scope.row.product.sku_code"
                          >({{ scope.row.product.sku_code }})</span
                        >
                      </div>
                      <div class="mb text-light">
                        等级/规格：{{ $empty.empty(scope.row.product.grade) }}
                        <span v-if="scope.row.product.unit_type == 1">/件</span>
                        <span v-if="scope.row.product.unit_type == 2">/箱</span>
                        <span v-if="scope.row.product.unit_type == 3"
                          >/袋子</span
                        >
                      </div>
                      <div class="mb">
                        毛重/毛单价：{{
                          $empty.empty(scope.row.product.gross_weight)
                        }}【{{
                          tool.toDecimal2(
                            (((scope.row.product.price * 1000) /
                              scope.row.product.gross_weight) *
                              1000) /
                              100 /
                              1000000
                          )
                        }}元/斤】
                      </div>
                      <div class="mb-15">
                        净重/净单价：{{
                          $empty.empty(scope.row.product.weight)
                        }}【{{
                          tool.toDecimal2(
                            (((scope.row.product.price * 1000) /
                              scope.row.product.weight) *
                              1000) /
                              100 /
                              1000000
                          )
                        }}元/斤】
                      </div>
                      <div>
                        <div
                          v-if="
                            scope.row.product.supplier ||
                            scope.row.product.buyer
                          "
                        >
                          <span
                            >主供货商：{{
                              scope.row.product.supplier.fullname
                            }}</span
                          ><span v-if="scope.row.product.buyer"
                            >({{ scope.row.product.buyer.fullname }})</span
                          >
                        </div>
                        <div v-else>—</div>
                      </div>
                    </div>
                    <div v-else>—</div>
                  </template>
                </el-table-column>
                <el-table-column prop="qty" align="center" label="采购数量">
                  <template slot-scope="scope">
                    <span>{{ $empty.empty(scope.row.qty) }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="price" align="center" label="开市价(元)">
                  <template slot-scope="scope">
                    <span>{{
                      tool.toDecimal2((scope.row.price * 10000) / 100 / 10000)
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="product_amt"
                  align="center"
                  label="商品总额"
                >
                  <template slot-scope="scope">
                    <span>{{
                      tool.toDecimal2(
                        (scope.row.product_amt * 10000) / 100 / 10000
                      )
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="purchase_amt"
                  align="center"
                  label="服务费(元)"
                >
                  <template slot-scope="scope">
                    <span>{{
                      tool.toDecimal2(
                        (scope.row.purchase_amt * 10000) / 100 / 10000
                      )
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="freight_amt"
                  align="center"
                  label="运费(元)"
                >
                  <template slot-scope="scope">
                    <span>{{
                      tool.toDecimal2(
                        (scope.row.freight_amt * 10000) / 100 / 10000
                      )
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="finance_amt"
                  align="center"
                  label="金融手续费(元)"
                >
                  <template slot-scope="scope">
                    <span>{{
                      tool.toDecimal2(
                        (scope.row.finance_amt * 10000) / 100 / 10000
                      )
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="actual_payment"
                  align="center"
                  label="总金额(元)"
                >
                  <template slot-scope="scope">
                    <span>{{
                      tool.toDecimal2(
                        (((scope.row.product_amt * 10000) / 100 / 10000) *
                          10000 +
                          ((scope.row.purchase_amt * 10000) / 100 / 10000) *
                            10000 +
                          ((scope.row.freight_amt * 10000) / 100 / 10000) *
                            10000 +
                          ((scope.row.finance_amt * 10000) / 100 / 10000) *
                            10000) /
                          10000
                      )
                    }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="订单创建时间" prop="create_time">
                  <div>{{ $empty.empty(merchantList.create_time) }}</div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="订单支付时间" prop="spay_attoreType">
                  <div v-if="merchantList.pay_at">
                    {{ $empty.time(merchantList.pay_at) }}
                  </div>
                  <div v-else>—</div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="订单取消时间" prop="cancel_at">
                  <div v-if="merchantList.cancel_at">
                    {{ $empty.time(merchantList.cancel_at) }}
                  </div>
                  <div v-else>—</div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="订单完成时间" prop="receive_at">
                  <div v-if="merchantList.receive_at">
                    {{ $empty.time(merchantList.receive_at) }}
                  </div>
                  <div v-else>—</div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </div>

    <div class="fy1">
      <el-button @click="$router.back()">返回</el-button>
    </div>
    <div v-show="showImage" class="shadow" @click="showImage = false"></div>
    <div v-show="showImage" class="bigImage">
      <img :src="bigImage" alt="" />
      <i class="el-icon el-icon-close bigClose" @click="showImage = false"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      merchantList: {},
      id: "",
      bigImage: "",
      showImage: false,
      goodsData: [
        //商品信息
      ],
      loading: false,
    };
  },
  created() {
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail();
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      this.$api.delivery.deliveryOrderInfo({ id: this.id }).then((res) => {
        this.merchantList = res.data;
        this.goodsData = res.data.order_detail;
      });
    },
    getGlass(src) {
      this.bigImage = src;
      this.showImage = true;
    },
    // 商品信息合计方法
    goodsCount(param) {
      const { columns, data } = param;
      console.log(data);
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = "合计";
          return;
        }
      });
      sums["3"] = this.merchantList.number;
      sums["5"] =
        this.tool.toDecimal2(
          (this.merchantList.product_amt * 10000) / 100 / 10000
        ) + "元";
      sums["6"] =
        this.tool.toDecimal2(
          (this.merchantList.purchase_amt * 10000) / 100 / 10000
        ) + "元";
      sums["7"] =
        this.tool.toDecimal2(
          (this.merchantList.freight_amt * 10000) / 100 / 10000
        ) + "元";
      sums["8"] =
        this.tool.toDecimal2(
          (this.merchantList.finance_amt * 10000) / 100 / 10000
        ) + "元";
      sums["9"] =
        this.tool.toDecimal2(
          (this.merchantList.order_amt * 10000) / 100 / 10000
        ) + "元";
      return sums;
    },
  },
};
</script>

<style lang="less">
.receorderdetail-container {
  .kv {
    width: 100px;
    height: 100px;
  }

  .sale-font {
    font-size: 15px;
  }

  padding: 10px;
  background-color: #ffffff;
  box-sizing: border-box;
  // min-height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  .el-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .auto-table-flex {
    overflow-y: auto;
  }
  .warehouse-wrapper {
    width: 302px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    background: #f2fcff;
    border-radius: 2px;
    border: 1px dashed #349ee6;
    margin-bottom: 20px;
    cursor: pointer;

    .plus {
      width: 14px;
      height: 14px;
    }
  }

  .warehouse-container {
    .warehouse-box {
      border-radius: 2px;
      border: 1px solid #dfe3ef;
      margin-bottom: 20px;
      padding: 11px 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        line-height: 1;
      }

      .box-img {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }

  .footerbtn {
    position: fixed;
    right: 20px;
    top: 13%;
  }

  .allmoney {
    position: fixed;
    left: 210px;
    right: 20px;
    height: 76px;
    padding: 0 30px;
    line-height: 76px;
    background: #fffcf9;
    box-shadow: 0px -3px 8px 0px rgba(255, 140, 89, 0.3);
    border-radius: 6px;
    bottom: 40px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .allmoney-font {
    font-size: 18px;
  }

  .auto-table-flex .el-table__footer-wrapper tbody td.el-table__cell {
    background-color: #eff6ff;
  }

  // .auto-table-flex .el-table .el-table__footer-wrapper .cell {
  //   color: #E65A34;
  // }
}
</style>
